var splashYoutubeID = 'Zt7vM6WhpCU';
var devlogYoutubeID = '_cX65AQvBjQ';

var tv;
var devlog;

document.addEventListener('DOMContentLoaded', function () {
}); // end DOMContentLoaded / onReady

window.addEventListener('load', function (event) {
  // console.log("All resources finished loading!");
  pageLoad();
  // if (tv.playVideo() !== undefined) {
  //   tv.playVideo();
  // }
  if(tv) {
    var fn = function(){ tv.playVideo(); }
    setTimeout(fn, 500);
  }
  vidRescale();
});

window.addEventListener('resize', function (event) {
  vidRescale();
});

function pageLoad() {

  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    threshold: 500,
    // ... more custom settings?
  });

}

var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/player_api';

var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var playerDefaults = {
  autoplay: 1,
  autohide: 1,
  modestbranding: 1,
  rel: 0,
  showinfo: 0,
  controls: 0,
  disablekb: 1,
  enablejsapi: 1,
  playsinline: 1,
  videoId: splashYoutubeID,
  playlist: splashYoutubeID,
  loop: 1,
  iv_load_policy: 3
};

function onYouTubePlayerAPIReady(){
  tv = new YT.Player('tv', {events: {'onReady': onPlayerReady, 'onStateChange': onPlayerStateChange}, playerVars: playerDefaults});
}

function onPlayerReady(){
  tv.mute();
  tv.playVideo();
  vidRescale();
  watchSplashVideo();

  devlog = new YT.Player('yt-devlog', {
    width: '1920',
    height: '1080',
    videoId: devlogYoutubeID,
    playsinline: 1,
    modestbranding: 1,
    rel: 0,
    enablejsapi: 1,
  });
}

function onPlayerStateChange(e) {
  if (e.data === 1){
    document.querySelector('#tv').classList.add('active');
    vidRescale();
    tv.playVideo();
  }
  if (e.data == YT.PlayerState.ENDED) {
    tv.seekTo(0);
    tv.playVideo();
  }
}

function onPlayerReady2(){};
function onPlayerStateChange2(e) {}

function vidRescale(){
  let cropSize = 120;

  var w = window.innerWidth + cropSize;
  var h = window.innerHeight + cropSize;
  let screen = document.getElementById('tv');
  let screenWidth = window.getComputedStyle(screen).getPropertyValue('width');
  screenWidth = parseInt(screenWidth.replace(/px/,""));
  // console.log('vidrescale with screenWidth: ', screenWidth, ' and w/h: ', w, " / ", h);
  if (w/h > 16/9){
    tv.setSize(w, w/16*9);
  } else {
    tv.setSize(h/9*16, h)
  }
  let screenLeft = -( (screenWidth+cropSize) - w ) / 2;
  screen.style.left = screenLeft + "px";
}

function watchSplashVideo() {
  let tvScreen = document.getElementById('tv');
  let tvScrollWatcher = scrollMonitor.create( tvScreen, 300 );

  tvScrollWatcher.enterViewport(function() {
      if (tv.getPlayerState()) {
        tv.playVideo();
        console.log('playing video');
      }
  });
  tvScrollWatcher.exitViewport(function() {
      if (tv.getPlayerState()) {
        tv.pauseVideo();
        console.log('pausing video');
      }
  });
}
